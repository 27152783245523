:root {
  --sidebar-width: 280px;
}

.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--background-dark);
  color: var(--text-light);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease, overflow 0.3s ease;
}

.sidebar.collapsed {
  width: 80px;
  overflow: visible;
}

.sidebar.collapsed .nav-link span:not(.nav-icon) {
  display: none;
}

.sidebar.collapsed .nav-badge {
  display: none;
}

.sidebar.collapsed .user-section {
  display: block;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background: transparent;
  border: none;
  color: var(--text-muted);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.icon-button:hover {
  background-color: var(--hover-light);
  color: var(--text-light);
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-light);
}

.logo-text .ai-text {
  color: var(--accent-color);
}

.nav-section {
  margin-bottom: 0.75rem;
}

.nav-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0.5rem;
  color: var(--text-muted);
  text-decoration: none;
  border-radius: 0.375rem;
  margin-bottom: 0.25rem;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
}

.nav-link:hover {
  background-color: var(--hover-light);
  color: var(--text-light);
}

.nav-link.active {
  background-color: var(--hover-light);
  color: var(--text-light);
}

.nav-icon {
  margin-right: 0.75rem;
  font-size: 1.25rem;
  opacity: 0.8;
}

.nav-badge {
  background-color: var(--accent-color);
  color: var(--text-light);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  margin-left: auto;
}

.divider {
  height: 1px;
  background-color: var(--border-color);
  margin: 0.75rem 0;
}

/* User dropdown styles */
.user-section {
  margin-top: 0.5rem;
}

.user-dropdown-toggle {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  background: transparent;
  border: none;
  color: var(--text-muted);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.user-dropdown-toggle:hover {
  background-color: var(--hover-light);
  color: var(--text-light);
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.user-name {
  flex-grow: 1;
  text-align: left;
  margin-right: 0.5rem;
  font-weight: 500;
}

.dropdown-menu {
  width: 220px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: var(--background-darker);
  border: 1px solid var(--border-muted);
  box-shadow: 0 10px 15px -3px var(--shadow-color);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: var(--text-muted);
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.dropdown-item:hover {
  background-color: var(--border-muted);
  color: var(--text-light);
}

.dropdown-divider {
  border-color: var(--border-muted);
  margin: 0.5rem 0;
}

.nav-link.collapsed {
  justify-content: center;
  padding: 0.75rem;
}

.nav-link.collapsed .nav-icon {
  margin-right: 0;
}

/* Update App.css main-content margin */
.main-content {
  margin-left: var(--sidebar-width);
  min-height: 100vh;
  width: calc(100% - var(--sidebar-width));
  transition: margin-left 0.3s ease, width 0.3s ease;
}

/* Add custom scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: var(--border-muted);
}

.sidebar.collapsed .user-dropdown-toggle {
  padding: 0.75rem;
  justify-content: center;
}

.sidebar.collapsed .user-dropdown-toggle .user-name,
.sidebar.collapsed .user-dropdown-toggle .ms-auto {
  display: none;
}

.sidebar.collapsed .user-avatar {
  margin-right: 0;
}

.sidebar.collapsed .dropdown-menu {
  left: 100%;
  top: auto;
  bottom: 0;
  transform: translateX(8px);
}

.sidebar.collapsed .user-dropdown-toggle:hover {
  background-color: var(--hover-light);
  border-radius: 0.375rem;
}

.sidebar-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hover-dark);
  z-index: 1029;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.sidebar-backdrop.show {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 991.98px) {
  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    box-shadow: none;
  }

  .sidebar.mobile-open {
    transform: translateX(0);
    box-shadow: 4px 0 8px var(--shadow-darker);
  }

  .sidebar-backdrop {
    display: block;
    opacity: 0;
    pointer-events: none;
  }

  .sidebar-backdrop.show {
    opacity: 1;
    pointer-events: auto;
  }

  .main-content {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .sidebar .icon-button {
    display: none;
  }
}

.nav-link span {
  flex-grow: 1;
}
