#App {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.logo-text {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.logo-text .ai-text {
  color: #007bff;
}

.main-content {
  margin-left: var(--sidebar-width);
  min-height: 100vh;
  width: calc(100% - var(--sidebar-width));
  transition: margin-left 0.3s ease, width 0.3s ease;
  position: relative;
  z-index: 1;
}

.sidebar {
  z-index: 1030;
  position: relative;
}

.dropdown-menu {
  z-index: 1040 !important;
  position: relative;
}

@media (max-width: 991.98px) {

  .main-content {
    margin-left: 0 !important;
    width: 100% !important;
  }
}