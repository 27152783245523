#SalesList {
  height: 100%;
  overflow: hidden;
}

.sales-content {
  flex-grow: 1;
  overflow: auto;
}

.sales-sidebar {
  width: var(--secondary-nav-width);
  flex-shrink: 0;
}

.meetings-controls {
  padding: 8px 16px;
}

.meetings-tabs {
  padding: 0 16px;
  background-color: var(--text-light);
  border-bottom: 1px solid var(--border-muted);
}

.tabs-container {
  display: flex;
  gap: 24px;
}

.tab-button {
  padding: 12px 16px;
  background: none;
  border: none;
  color: var(--text-muted);
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.tab-button:hover {
  color: var(--text-dark);
}

.tab-button.active {
  color: var(--primary-color);
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--primary-color);
}

.meetings-container {
  background-color: var(--background-light);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.meetings-list {
  background-color: var(--text-light);
  border: none;
  box-shadow: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.meeting-item {
  cursor: pointer;
  border-bottom: 1px solid var(--border-muted);
  transition: background-color 0.2s ease;
}

.meeting-item:last-child {
  border-bottom: none;
}

.meeting-item:hover {
  background-color: var(--background-light);
}

.meeting-row {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  position: relative;
}

.meeting-content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  font-size: var(--font-size-sm);
  min-width: 0;
  position: relative;
}

.meeting-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 80px;
}

.recipient,
.creator {
  width: 150px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipient {
  font-weight: 400;
  color: var(--text-dark);
}

.creator {
  color: var(--text-muted);
  font-weight: 400;
}

.duration {
  width: 70px;
  flex-shrink: 0;
  color: var(--text-dark);
  font-weight: 600;
}

.meeting-name {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-muted);
}

.meeting-title {
  font-weight: 600;
  color: var(--text-dark);
  margin-right: 8px;
}

.timestamp-today,
.timestamp-this-year,
.timestamp-other-year {
  flex-shrink: 0;
  text-align: right;
  color: var(--text-dark);
  font-weight: 400;
}

.hover-actions {
  display: none;
  position: relative;
  right: 0;
  background-color: var(--background-light);
  padding: 0;
  margin: 0;
  border-radius: 4px;
  z-index: 1;
}

.meeting-item:hover .hover-actions {
  display: flex;
  gap: 8px;
}

.meeting-item:hover .duration,
.meeting-item:hover .timestamp-today,
.meeting-item:hover .timestamp-this-year,
.meeting-item:hover .timestamp-other-year {
  display: none;
}

.btn-icon {
  padding: 4px;
  background: none;
  border: none;
  color: var(--text-dark);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon:hover {
  color: var(--text-dark);
  background-color: var(--hover-light);
  border-radius: 50%;
}

.star-icon {
  color: var(--text-muted);
}

.star-icon.filled {
  color: var(--warning);
}

.pagination-info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-size-sm);
}

.pagination-info .btn-icon {
  font-size: 20px;
  padding: 4px 8px;
}

.pagination-info .btn-icon:disabled {
  color: var(--text-muted);
  opacity: 0.5;
  cursor: not-allowed;
}

/* Status Icons */
.status-icon {
  margin-right: 8px;
  font-size: 14px;
}

.status-ready {
  color: var(--success);
}

.status-processing {
  color: var(--primary-color);
}

.status-transcribing {
  color: var(--info);
}

.status-error {
  color: var(--danger);
}

/* Mobile styles */
.mobile-meetings-list {
  display: none;
}

.mobile-meeting-card {
  background: var(--text-light);
  border-radius: 8px;
  margin: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.mobile-meeting-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.mobile-meeting-title {
  padding-right: 60px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  color: var(--text-dark);
}

.mobile-meeting-main {
  margin-bottom: 12px;
}

.mobile-meeting-participants {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}

.mobile-meeting-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid var(--border-muted);
}

.mobile-meeting-stats {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--text-muted);
  font-size: var(--font-size-sm);
}

.mobile-meeting-actions {
  display: flex;
  gap: 8px;
}

@media (max-width: 767.98px) {
  .sales-sidebar {
    display: none;
  }

  #SalesList {
    flex-direction: column;
  }

  .sales-content {
    overflow: visible;
  }

  .meetings-list {
    display: none;
  }

  .mobile-meetings-list {
    display: block;
  }

  .meetings-tabs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .tabs-container {
    gap: 16px;
    padding: 0 8px;
  }

  .tab-button {
    padding: 12px 8px;
    white-space: nowrap;
  }

  .meetings-controls {
    padding: 8px;
  }
} 