.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: var(--text-light);
  color: var(--text-dark);
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-xl);
}

.empty-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.empty-chat h1 {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-xl);
  color: var(--primary-color);
}

.suggestion-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  max-width: 600px;
  width: 100%;
}

.suggestion-btn {
  background-color: var(--background-light);
  border: 1px solid var(--border-muted);
  color: var(--text-dark);
  padding: var(--spacing-md);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.suggestion-btn:hover {
  background-color: var(--border-muted);
}

.message {
  margin-bottom: var(--spacing-lg);
  display: flex;
  justify-content: flex-start;
}

.message.ai {
  background-color: var(--background-light);
}

.message.user {
  background-color: transparent;
}

.message-content {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-md);
  width: 100%;
  border-radius: 0.5rem;
}

.chat-input-container {
  padding: var(--spacing-md) var(--spacing-xl);
  background-color: var(--text-light);
  border-top: 1px solid var(--border-muted);
}

.chat-input-wrapper {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: var(--background-light);
  border-radius: 0.5rem;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-muted);
}

.chat-input {
  flex: 1;
  background: transparent;
  border: none;
  color: var(--text-dark);
  padding: var(--spacing-sm);
  font-size: var(--font-size-base);
}

.chat-input:focus {
  outline: none;
}

.attachment-button,
.send-button {
  background: transparent;
  border: none;
  color: var(--text-muted);
  padding: var(--spacing-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.attachment-button:hover,
.send-button:hover:not(:disabled) {
  color: var(--primary-color);
} 