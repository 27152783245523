:root {
    /* Colors */
    --primary-color: #0d6efd;
    --secondary-color: #6c757d;
    --background-dark: #252f3f;
    --background-darker: #1f2937;
    --background-darker-alt: #404b5f;
    --background-light: #f5f5f5;
    --text-light: #fff;
    --text-dark: #000;
    --text-muted: #9ca3af;
    --border-color: rgba(255, 255, 255, 0.1);
    --hover-light: rgba(255, 255, 255, 0.08);
    --hover-dark: rgba(0, 0, 0, 0.5);
    --accent-color: #818cf8;
    --accent-color-alt: #6366f1;
    --border-muted: rgba(0, 0, 0, 0.15);
    --shadow-color: rgba(0, 0, 0, 0.1);
    --shadow-darker: rgba(0, 0, 0, 0.2);
    --hover-light-alt: rgba(255, 255, 255, 0.1);
    --warning: #ffc107;
    --border-muted-alt: rgba(0, 0, 0, 0.1);
    
    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;
    
    /* Layout */
    --sidebar-width: 280px;
    --secondary-nav-width: 350px;
    --mobile-nav-width: 280px;
    
    /* Typography */
    --font-family-base: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --font-size-sm: 0.875rem;
    --font-size-base: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
}