#SalesEditor {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--text-light);
}

#SalesEditor .editor-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

#SalesEditor .editor-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
}

#SalesEditor .editor-header {
  padding: 2rem 4rem 1rem 4rem;
  background: var(--text-light);
}

#SalesEditor .editor-header > div {
  max-width: 900px;
  margin: 0 auto;
}

#SalesEditor .meeting-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

#SalesEditor .meeting-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-dark);
  margin: 0;
}

#SalesEditor .meeting-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-muted);
  font-size: var(--font-size-sm);
}

#SalesEditor .editor-content {
  flex: 1;
  overflow: auto;
  padding: 2rem 4rem;
  background: var(--text-light);
}

#SalesEditor .analysis-content {
  max-width: 900px;
  margin: 0 auto;
}

#SalesEditor .editor-footer {
  flex-shrink: 0;
  border-top: 1px solid var(--border-muted);
  background: var(--text-light);
  padding: 0 1rem;
}

#SalesEditor .audio-player-container {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 56px;
  border-top: none;
}

#SalesEditor .audio-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  max-width: 600px;
}

#SalesEditor .time-display {
  font-size: var(--font-size-sm);
  color: var(--text-muted);
  font-variant-numeric: tabular-nums;
  min-width: 85px;
}

#SalesEditor .time-display::after {
  content: " / 00:00";
}

#SalesEditor .playback-speed {
  padding: 0.25rem 0.5rem;
  border: none;
  background: none;
  color: var(--text-dark);
  cursor: pointer;
  font-size: var(--font-size-sm);
  border-radius: 0.25rem;
}

#SalesEditor .playback-speed:hover {
  background-color: var(--hover-light);
  color: var(--text-darker);
}

#SalesEditor .player-button {
  background: none;
  border: none;
  color: var(--text-dark);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

#SalesEditor .player-button:hover {
  background-color: var(--hover-light);
  color: var(--text-darker);
}

#SalesEditor .player-button.play {
  background-color: var(--primary-color);
  color: var(--text-light);
}

#SalesEditor .player-button.play:hover {
  background-color: var(--accent-color);
  color: var(--text-light);
}

#SalesEditor .progress-bar {
  flex: 1;
  height: 4px;
  background-color: var(--border-muted);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s;
}

#SalesEditor .progress-bar:not([style*="cursor: pointer"]) {
  opacity: 0.5;
}

#SalesEditor .progress-bar-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 2px;
}

#SalesEditor .progress-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  display: none;
}

#SalesEditor .progress-bar:hover .progress-handle {
  display: block;
}

#SalesEditor .transcript-panel {
  width: 300px;
  border-left: 1px solid var(--border-muted);
  background: var(--text-light);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#SalesEditor .transcript-header {
  padding: 1rem;
  border-bottom: none;
  font-weight: 600;
}

#SalesEditor .transcript-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

#SalesEditor .transcript-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: none;
}

#SalesEditor .transcript-speaker {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

#SalesEditor .transcript-text {
  color: var(--text-muted);
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

#SalesEditor .transcript-timestamp {
  font-size: var(--font-size-xs);
  color: var(--text-muted);
  margin-top: 0.25rem;
}

#SalesEditor .form-section {
  max-width: 900px;
  margin: 0 auto 2rem auto;
}

#SalesEditor .form-label {
  margin-bottom: 0.75rem;
  font-size: var(--font-size-sm);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--text-muted);
}

#SalesEditor .form-control,
#SalesEditor .form-select {
  border: 1px solid var(--border-muted);
  background-color: var(--text-light);
}

#SalesEditor .form-control:focus,
#SalesEditor .form-select:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

#SalesEditor .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

#SalesEditor .form-check-label {
  color: var(--text-dark);
}

#SalesEditor .form-divider {
  max-width: 900px;
  margin: 2rem auto;
  border-top: 1px solid var(--border-muted);
  opacity: 0.5;
}

#SalesEditor .close-button {
  font-size: 24px;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  color: var(--text-muted);
  font-weight: 300;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#SalesEditor .close-button:hover {
  color: var(--text-dark);
  background-color: var(--hover-light);
}

#SalesEditor .transcript-empty {
  padding: 2rem;
  text-align: center;
  color: var(--text-muted);
  font-size: var(--font-size-sm);
}

#SalesEditor .time-display-mobile {
  display: none;
}

@media (max-width: 991.98px) {
  #SalesEditor .transcript-panel {
    display: none;
  }

  #SalesEditor .editor-header {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  #SalesEditor .editor-content {
    padding: 1rem;
    padding-bottom: 1rem;
  }

  #SalesEditor .editor-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--text-light);
    border-top: 1px solid var(--border-muted);
    padding: 0.5rem;
    z-index: 100;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  #SalesEditor .editor-container {
    padding-bottom: 160px;
  }

  #SalesEditor .editor-footer > div {
    flex-direction: column;
    gap: 0.5rem;
  }

  #SalesEditor .editor-footer .d-flex.gap-2 {
    order: -1;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-muted);
  }

  #SalesEditor .editor-footer .flex-grow-1 {
    width: 100%;
  }

  #SalesEditor .audio-player-container {
    max-width: 100%;
  }

  #SalesEditor .time-display {
    font-size: var(--font-size-sm);
    color: var(--text-muted);
    font-variant-numeric: tabular-nums;
    min-width: auto;
  }

  #SalesEditor .time-display::after {
    content: none;
  }

  #SalesEditor .time-display-mobile {
    display: inline;
  }
  
  #SalesEditor .time-display-desktop {
    display: none;
  }

  #SalesEditor .editor-content {
    padding-bottom: calc(1rem + 160px);
  }
}
