#NotFoundPage {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--background-light);
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: var(--spacing-md);
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: var(--spacing-sm);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
