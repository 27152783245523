#LoginPage {
  min-height: 100vh;
  display: flex;
}

.login-section {
  flex: 0 0 100%;
  max-width: 100%;
  padding: var(--spacing-xl);
  background: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.marketing-section {
  display: none;
}

.login-container {
  width: 100%;
  max-width: 360px;
  padding: 0 var(--spacing-md);
}

.back-button {
  position: absolute;
  top: var(--spacing-xl);
  left: var(--spacing-xl);
}

.social-login-button {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: var(--spacing-sm);
  border: 1px solid var(--border-muted);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--text-light);
  cursor: pointer;
  transition: background-color 0.2s;
}

.social-login-button:hover {
  background-color: var(--background-light);
}

.sign-in-button {
  background: var(--primary-color);
  border: none;
  color: var(--text-light);
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  font-weight: 500;
}

.sign-in-button:hover {
  background: var(--accent-color);
}

.marketing-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: var(--spacing-md);
}

.marketing-hashtag {
  color: var(--accent-color);
  font-size: 2.5rem;
  margin-bottom: var(--spacing-xl);
}

.marketing-description {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-xl);
  max-width: 600px;
}

.marketing-stats {
  font-size: var(--font-size-base);
  opacity: 0.9;
}

.btn-link {
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
}

.btn-link:hover {
  color: inherit;
  text-decoration: underline;
}

.back-button span {
  margin-right: var(--spacing-sm);
}

.form-control {
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
}

@media (min-width: 768px) {
  .login-section {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .marketing-section {
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
    color: var(--text-light);
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xl);
    text-align: center;
  }
}

.logo-text {
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.ai-text {
  color: var(--accent-color);
}

.social-login-separator {
  position: relative;
  text-align: center;
  margin: var(--spacing-lg) 0;
}

.social-login-separator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--border-muted);
}

.social-login-separator-text {
  position: relative;
  background-color: var(--text-light);
  padding: 0 var(--spacing-md);
  color: var(--text-muted);
  font-size: var(--font-size-sm);
}
