.mobile-top-menu {
  height: 60px;
}

.menu-button,
.chat-button {
  background: none;
  border: none;
  color: var(--text-muted);
  padding: var(--spacing-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-button:hover,
.chat-button:hover {
  color: var(--text-light);
} 