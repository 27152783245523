.secondary-nav {
  width: var(--secondary-nav-width);
  height: 100vh;
  background-color: var(--background-darker);
  color: var(--text-light);
  border-right: 1px solid var(--border-color);
}

.secondary-nav-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 12px 0.5rem var(--spacing-xs);
  border-bottom: 1px solid var(--border-color);
}

.notebook-title {
  font-size: var(--font-size-xl);
  font-weight: 500;
  margin: 0;
  color: var(--text-light);
}

.secondary-nav-content {
  padding: var(--spacing-md) 0;
}

.nav-section {
  margin-bottom: var(--spacing-xl);
}

.secondary-nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem var(--spacing-md);
  color: var(--text-muted);
  text-decoration: none;
  transition: all 0.2s;
}

.secondary-nav-link:hover {
  background-color: var(--hover-light-alt);
  color: var(--text-light);
}

.secondary-nav-link.active {
  background-color: var(--accent-color-alt);
  color: var(--text-light);
}

.nav-icon {
  margin-right: 0.75rem;
  font-size: var(--font-size-lg);
  opacity: 0.8;
}

.channels-section {
  padding: 0 var(--spacing-md);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  font-size: var(--font-size-sm);
  font-weight: 600;
  color: var(--text-muted);
  text-transform: uppercase;
}

.add-channel-btn {
  background: none;
  border: none;
  color: var(--text-muted);
  cursor: pointer;
  padding: var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.add-channel-btn:hover {
  background-color: var(--hover-light-alt);
  color: var(--text-light);
}

.channels-placeholder {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  color: var(--text-muted);
  font-size: var(--font-size-sm);
}

.create-channel-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  margin-top: var(--spacing-md);
  background: none;
  border: 1px solid var(--border-color);
  color: var(--text-muted);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.create-channel-btn:hover {
  background-color: var(--hover-light-alt);
  color: var(--text-light);
}

/* Add mobile styles */
@media (max-width: 991.98px) {
  .secondary-nav {
    width: var(--mobile-nav-width);
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1030;
  }

  .secondary-nav.mobile-open {
    transform: translateX(0);
    box-shadow: 4px 0 8px var(--shadow-darker);
  }

  .back-button {
    background: none;
    border: none;
    color: var(--text-muted);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
    position: unset;
  }

  .back-button:hover {
    background-color: var(--hover-light-alt);
    color: var(--text-light);
  }
} 