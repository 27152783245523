#SalesDashboard {
  height: 100%;
  overflow: hidden;
}

.sales-content {
  flex-grow: 1;
  overflow: auto;
}

.dashboard-iframe-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  min-height: 600px;
  background: var(--text-light);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.dashboard-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991.98px) {
  #SalesDashboard {
    flex-direction: column;
  }

  .sales-content {
    overflow: visible;
    margin-left: 0;
  }

  .dashboard-iframe-container {
    height: 500px;
    min-height: auto;
  }
} 