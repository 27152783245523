#AuthPage {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--background-light);
}

.form-auth {
  width: 100%;
  max-width: 330px;
  padding: var(--spacing-md);
  margin: auto;
}

.form-auth .checkbox {
  font-weight: 400;
}

.form-auth .form-floating:focus-within {
  z-index: 2;
}

.form-auth input[type="email"],
.form-auth input[type="password"] {
  margin-bottom: -1px;
  border-radius: 0;
}

.form-auth .social-login {
  margin-top: var(--spacing-xl);
  position: relative;
  text-align: center;
}

.form-auth .social-login:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--border-muted);
  z-index: 1;
}

.form-auth .social-login-text {
  display: inline-block;
  padding: 0 var(--spacing-sm);
  background-color: var(--background-light);
  position: relative;
  z-index: 2;
  color: var(--secondary-color);
}

.btn-google {
  color: var(--text-dark);
  background-color: var(--text-light);
  border: 1px solid var(--border-muted);
}

.btn-google:hover {
  background-color: var(--background-light);
  color: var(--text-dark);
}

.btn-google img {
  margin-right: var(--spacing-sm);
  width: 18px;
  height: 18px;
}

.auth-links {
  font-size: var(--font-size-sm);
}

.auth-links a {
  color: var(--primary-color);
  text-decoration: none;
}

.auth-links a:hover {
  text-decoration: underline;
}
