.presales-sidebar-container {
  background-color: var(--background-light);
  border-left: 1px solid var(--border-color);
  height: 100%;
  overflow: auto;
}

.presales-sidebar-container .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-light);
}

.presales-sidebar-container .btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--text-light);
}

.presales-sidebar-container .btn-success {
  color: var(--text-light);
}

.presales-sidebar-container .btn-danger {
  color: var(--text-light);
}

.presales-sidebar-container .form-label {
  color: var(--text-dark);
}

.presales-sidebar-container .border-bottom {
  border-color: var(--border-color) !important;
}

.presales-sidebar-container .text-muted {
  color: var(--text-muted) !important;
} 